// Here you can add other styles
$cursor: pointer;

.w-5 {
  width: 5% !important;
}

.w-8 {
  width: 8% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10%;
}

.w-17 {
  width: 17%;
  text-align: center;
}

.w-18 {
  width: 18%;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-100 {
  width: 100%;
}

.col-4 {
  width: 33.33%;
}

.bg-1 {
  background-color: #452b90 !important;
}

.bg-2 {
  background-color: #3a9b94 !important;
}

.bg-3 {
  background-color: #089bab !important;
}

.bg-4 {
  background-color: #a54873 !important;
}

.datatable {
  border-radius: 10px 10px 0 0;
}

.btn-danger {
  background-color: #a90909;
  border-color: #a90909;
}

.btn-warning {
  color: #fff;
}

.datatable .rdt_Pagination {
  border-radius: 0px 0px 10px 10px;
}

.card {
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.generate_report {
  color: #222b40 !important;
  font-weight: 500;
}

.Inactive {
  background: #c71717;
  border-radius: 5px;
  color: #fff !important;
  padding: 8px 15px !important;
  width: 5rem;
  text-align: center;
  font-weight: 500;
}

.Active {
  background-color: #008000;
  border-radius: 5px;
  color: #fff !important;
  padding: 8px 15px !important;
  width: 5rem;
  text-align: center;
  font-weight: 500;
}

.user_profile_img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.user_profile_img img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.submit_btn {
  background-color: #31505f !important;
  color: #fff !important;
  border: 1px solid #31505f !important;
}

.submit_btn:hover {
  background-color: hsla(186, 71%, 42%, 0.849) !important;
  border: 1px solid hsla(186, 71%, 42%, 0.849) !important;
}

.toggle_table_data {
  color: #595d6e;
  font-weight: 400;
  padding: 0 70px !important;
  font-size: 14px;
}

.toggle_table_data div span {
  margin-right: 8px;
  font-weight: 500;
}

.cancel_btn {
  color: #089bab !important;
  border: transparent !important;
}

.cancel_btn:hover {
  background-color: #089bab !important;
  color: #fff !important;
}

.btn_primar {
  background: rgba(8, 155, 171, 0.13);
  border: 1px solid #dff2f4;
  border-radius: 5px;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: #089bab;
}

// all.js component 22-06-2023
.rdt_Table {
  border-radius: 10px 10px 0px 0px !important;
}

.rdt_TableRow:hover {
  background-color: #eeeeeeb0 !important;
}

.action-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.edit-btn {
  padding: 5px;
  text-align: center;
  color: #17a2b8;
}

.delet-btn {
  color: #fd397a;
  padding: 5px;
  text-align: center;
}

.message-btn {
  color: #2eb85c;
  padding: 5px;
  text-align: center;
}

.MailLogdelet-btn {
  color: #17a2b8;
  padding: 5px;
  text-align: center;
}

.restore-btn {
  transform: scaleX(-1);
}

.subheader-custom {
  background: #fff;

  .delete_btn {
    background: #ffebf1;
    border: 1px solid #ffebf1;
    color: #fd1361;
    font-size: 0.7rem;
    border-radius: 5px;
    font-weight: 500;
  }

  .delete_btn:hover {
    color: #ffebf1;
    background-color: #fd1361;
    border-color: #fc0758;
  }

  .search_bar .search_bar_box {
    border-color: #089bab;
    border: 1px solid #089bab;
    border-radius: 3px;
    height: 32px;
    padding: 4px 10px;
  }

  .search_bar .search_bar_box::placeholder {
    color: #17a2b8;
    font-size: 15px;
  }

  .search_bar input:focus-visible {
    border-color: #089bab;
    border: 1px solid #089bab;
    border-radius: 3px;
    padding: 4px 10px;
    outline-color: rgba(0, 172, 191, 0.4901960784);
    outline-width: thin;
    height: 32px;
  }

  .search_icon {
    position: absolute;
    width: 20px !important;
    height: 20px !important;
    right: 5px;
    top: 5px;
    color: #17a2b8;
  }
}

// pagination
.pagination-container {
  align-items: center;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 15px 13px 15px 13px;
}

.pagination-container .active {
  background: #17a2b8;
  color: #fff;
}

button.btn-pagination {
  background: #eef1ff;
  color: #17a2b8;
  border: NONE;
  font-size: 14px;
  margin: 0px 3px;
  border-radius: 5px;
  padding: 0px 0.2rem;
  min-width: 30px;
  min-height: 30px;
  font-weight: 500;
}

.per_page_row {
  font-size: 13px;
  color: #646c9a;
}

// paginationl end


// [data-column-id="3"].sc-jlZhew {
//     background-color: orange;
//     color: #fff;
//     justify-content: center;
//   }
.status-value {
  color: #fff;
  padding: 5px 8px;
  border-radius: 1.5rem;
  text-transform: capitalize;
  font-size: 10px;
  text-align: center;
  width: 6rem;
}

.row-color-white {
  background-color: #fff;
}

.row-color-grey {
  background-color: #f7f8fa;
}

.form-control:focus {
  border-color: 1px solid var(--cui-input-border-color, #b1b7c1);
  box-shadow: none;
}

.subheader_button_leftside,
.subheader_button_leftside:focus-visible,
.subheader_button_leftside:active,
.dropmenu_item_btn:active {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  margin: 0;
  padding: 0 1rem 0 0;
  font-size: 1rem;
  font-weight: 400;
  color: #434349 !important;
}

.subheader_button_leftside:hover {
  border-color: none;
  background-color: transparent;
  color: #434349;
}

.add_new {
  background: rgb(8 155 171 / 13%);
  border: 1px solid #dff2f4;
  border-radius: 5px;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: #089bab;
}

.add_new:hover,
.add_new:active,
.add_new:focus-visible {
  background-color: #089bab !important;
  border: 1px solid #089bab !important;
}

.dropmenu_item_btn:hover {
  background-color: #089bab;
  color: #fff;
}

.dropmenu_item_btn {
  background-color: transparent;
}

.add_plus_btn {
  color: #17a2b8;
  width: 20px !important;
  height: 100%;
}

.subheader_search_box {
  line-height: 0 !important;
  width: 20% !important;
  position: absolute;
  top: -67px;
  right: 50px;
  font-size: 0.7rem;
  border-radius: 0px;
}

//   .subheader_search_box:hover{
//     background: #089bab;
//     color: #fff;
//   }

// login page by saket start
.border-left {
  border-left: 1px solid #43434980;
  border-width: thin;
  height: 15px;
  margin-right: 10px;
}

.selected_row {
  margin-right: 20px;
  font-size: 12px;
}

.w-35 {
  width: 35%;
}

.bg-theme-color {
  background: #222b40;
  color: #fff;
  border: 1px solid #222b40;
}

.login_card {
  border: none !important;
  border-radius: 15px !important;
}

.admin-login-page {
  .form-control {
    border-radius: 5px;
    border-color: #e5e5e5;
  }

  .form-control:focus {
    border-radius: 5px;
    border: 1px solid #b1b7c1;
    background: #e8f0fe;
  }

  p {
    color: rgb(44 56 74) !important;
    font-size: 1rem;
  }

  // .sign-up,.sign-up:active{
  //   background:#38cab3;
  //   border-color: #38cab3;
  //   border-radius: 10px;

  // }
  .label-text {
    color: #212529;
    margin-bottom: 0.375rem;
    font-weight: 500;
    font-size: 0.875rem;
    margin-top: 9px;
  }
}

/* CSS */
.sign-up {
  // margin: 10px;
  padding: 8px 5px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  border: 0px;
  width: 100%;
  font-weight: 700;
  // box-shadow: 0px 0px 14px -7px #6de1e1;
  background: #222b40;
  // background-image: linear-gradient(45deg, #38cab3 0%, #03f4bccc 51%, #19ddc0 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.sign-up:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.sign-up:active {
  transform: scale(0.95);
}

// login page by saket end

// delet model
.modal-open {
  overflow: auto !important;
}

.delete_item_box {
  border-radius: 15px;

  .logo_x {
    border-radius: 50%;
    border: 2px solid #f27474;
    color: #f27474;
    font-size: 40px;
    line-height: 1.1;
    text-align: center;
    width: 55px;
    height: 55px;
  }

  .model_footer {
    border-top: none;
  }

  span {
    font-weight: normal;
    font-size: 0.9rem;
  }

  .model_btn {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  .close_btn {
    background: #17a2b8;
    border-color: #17a2b8;
    color: #fff;
  }

  .close_btn:hover {
    background: #17a2b8;
    border-color: #17a2b8;
    color: #fff;
  }

  .delete_btn {
    background: #fd397a;
    border: 1px solid #fd397a;
    color: #fff;
  }

  .delete_btn:hover {
    color: #fff;
    background-color: #fd1361;
    border-color: #fc0758;
  }

  .modal-dialog {
    width: 30%;
  }
}

.pointer_cursor {
  cursor: pointer;
  caret-color: #fff;
}

.data_Table_title {
  color: #595d6e;
  font-weight: 500;
}

.data_table_colum {
  color: #595d6e;
}

.XqRbW input,
.eIIIPq input {
  background: red !important;
  border: 1px solid transparent !important;
}

.dropdown-menu .dropdown-item {
  cursor: pointer;
}

// Rahul css update start
#root {
  overflow: auto;
  background-color: #ebedef;
}

.sidebar {
  // background-color: #222b40;
  background-color: var(--primaryColor);
}

.sidebar-brand,
.sidebar-toggler {
  // background-color: #222b40;
  background-color: var(--primaryColor);
}

.sidebar-nav {
  overflow-y: hidden;
}

.sidebar-nav:hover {
  overflow-y: scroll;
}

.sidebar-nav::-webkit-scrollbar {
  width: 5px;
  height: 150px;
}

/* Track */
.sidebar-nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
// .sidebar-nav::-webkit-scrollbar-thumb {
//   background: $scrollbar-Color;
//   border-radius: 10px;
// }
// .brand_highlight {
//   color: $highlight-Color;
// }
.form-label {
  color: #222b40;
  font-size: 13px;
  margin-bottom: 3px;
  font-weight: 500;
}

.form-check-input:checked {
  background-color: var(--secondaryColor);
  border-color: var(--secondaryColor);
}

//Radio buttons Code for Shop and User
// .activeBtn {
//   width: 70px;
//   .radioBtn_name {
//     width: 50px;
//     font-size: 13px;
//   }
// }
// .inactiveBtn {
//   width: 80px;
//   .radioBtn_name {
//     width: 60px;
//     font-size: 13px;
//   }
// }
.activeBtn {
  width: 70px;

  .radioBtn_name {
    width: 50px;
    font-size: 13px;
  }
}

.inactiveBtn {
  width: 80px;

  .radioBtn_name {
    width: 60px;
    font-size: 13px;
  }
}

.red-star::after {
  content: '*';
  color: red;
  margin-right: 4px;
  /* Add some spacing between the star and the label text */
}

/* Add margin or padding to create space between radio buttons and labels */
.form-check.maleBtn,
.form-check.femaleBtn {
  margin-right: 10px;
  /* Adjust this value as needed */
}

.blacklistBtn {
  width: 0px;

  .radioBtn_name {
    width: 70px;
    font-size: 13px;
  }
}

.maleBtn {
  width: 70px;

  .radioBtn_name {
    width: 50px;
    font-size: 13px;
  }
}

.femaleBtn {
  width: 80px;

  .radioBtn_name {
    width: 60px;
    font-size: 13px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.dark-widget .card-body div {
  color: #000;
}

// .bg-primary {
//   background-color: $bg-Primary !important;
// }
// .bg-warning {
//   background-color: $bg-Warning !important;
// }
// .bg-info {
//   background-color: $bg-Info !important;
// }
// .bg-secondary {
//   background-color: $bg-Secondary !important;
// }
.sidebar-nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
}

.footer {
  font-size: 0.875rem;
}

// .sidebar-nav .nav-link:hover {
//   color: $yellow-Golden;
//   text-decoration: none;
//   background: rgb(34 43 64);
// }
// .sidebar-nav .nav-link:hover .nav-icon {
//   color: $yellow-Golden;
// }
.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link:hover {
  color: var(--secondaryColor);
  background: transparent;
}

.sidebar-nav .nav-link.active .nav-icon,
.sidebar-nav .nav-group.show>.nav-group-toggle,
.sidebar-nav .nav-link.active .nav-icon,
.sidebar-nav .nav-group.show>.nav-group-toggle svg {
  color: var(--secondaryColor);
}

// .card-header {
//   background: $primary-Color;
//   color: #fff;
// }
// .jstree-default .jstree-node,
// .jstree-default .jstree-icon {
//   background-image: url(../assets/images/32px.png) !important;
// }
.preview_image img {
  width: 110px;
  height: 110px;
  background: #fff;
  border-radius: 10px;
  // margin-top: 20px;
  border: 1px solid #d2d2d5;
  object-fit: cover;
  object-position: top;
}

.multiple_images img {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #d2d2d5;
  object-fit: cover;
  margin-right: 10px;
}

.multiple_images .remove_featured {
  right: 10px !important;
  left: auto;
}

.remove_featured {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  padding: 3px;
  border-top-left-radius: 4px;
  width: 1.35rem;
  color: #c71717;
  cursor: pointer;
}

.ck-powered-by {
  display: none;
}

.form-switch-lg .form-check-input {
  margin-left: 0 !important;
  cursor: pointer;
}

.nestable-item-name {
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 4px;
  background: #fff;
}

.list-actions svg {
  cursor: pointer;
}

.navigation_type {
  font-size: 0.875rem;
}

.navigation_badge {
  color: #989898;
  font-size: 12px;
}

// Rahul css update end

// checkbox component start
.form-check {
  margin-bottom: 0px;
  min-height: 0px;
}

.kt-checkbox.kt-checkbox--solid>span {
  background: #e4e8ee;
  border: 1px solid transparent !important;
}

.kt-checkbox>span {
  border-radius: 3px;
  background: none;
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
}

.kt-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.kt-checkbox>input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.kt-checkbox.kt-checkbox--solid>input:checked~span {
  background: #dee2ea;
}

.kt-checkbox.kt-checkbox--solid>input:focus~span {
  border: 1px solid transparent !important;
}

.kt-checkbox>input:checked~span:after {
  display: block;
}

.kt-checkbox.kt-checkbox--solid>span:after {
  border: solid #99a6bf;
}

.kt-checkbox>span:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  margin-left: -2px;
  margin-top: -6px;
  width: 5px;
  height: 10px;
  border-width: 0 2px 2px 0
    /*rtl:ignore*/
    !important;
  -webkit-transform: rotate(45deg)
    /*rtl:ignore*/
  ;
  transform: rotate(45deg)
    /*rtl:ignore*/
  ;
}

// checkbox component end

// testimonial component
.testimonial_submit,
.testimonial_submit:hover {
  background-color: #089bab;
  color: #fff;
  border: 1px solid #089bab;
}

.testimonial_cancel {
  color: #089bab !important;

  border: transparent !important;
}

.testimonial_cancel:hover {
  background-color: #089bab !important;
  color: #fff !important;
}

.successmsg {
  color: #008000;
  font-size: 14px;
}

.succes-alert {
  background-color: #0abb87;
  color: #fff;
  font-size: 14px;
}

.succes-alert .btn-close {
  color: #fff !important;
  // background-color: red !important;
}

.alert-dismissible .btn-close {
  padding: 1rem !important;
  color: #fff !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px !important;
}

// galleries style
.upload_files {
  text-align: center;
  height: 140px;
  display: flex;
  position: relative;
  margin: auto;
  align-items: center;
  justify-content: center;
  border: 2px dashed #bec4d2;
  border-radius: 15px;
  background-color: #fff !important;
}

// .file_upload {
//   width: 100px;
// }
.upload_files input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  top: 0;
  left: 0;
}

.upload_files:hover {
  opacity: 0.6;
}

.Dropfie_preview {
  margin-top: 30px;
}

.Dropfie_preview p {
  font-weight: 500;
}

.Dropfie_preview_title {
  margin-bottom: 20px;
}

.Dropfie_preview_item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: #ffffffc4;
  padding: 15px;
  border-radius: 10px;
}

.Dropfie_preview_item img {
  width: 50px;
  margin-right: 20px;
}

.Dropfie_preview_title_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Dropfie_preview_item_dell {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
}

.Dropfie_preview_item:hover .Dropfie_preview_item_dell {
  opacity: 1;
}

.createfaq {
  overflow-x: hidden;
}


.card-footer1 {
  text-align: center;
  margin-top: 20px;
}

.dashboard_top_boxes .card {
  pointer-events: none;
}

.user_details {

  // .user_img_box {
  //   width: 35px;
  //   height: 35px;
  // }
  .network_img {
    width: 40px !important;
    height: 40px !important;

    img {
      width: 100% !important;
      padding: 4px;
      min-width: 100% !important;
      height: 100% !important;
      object-fit: contain !important;
    }
  }

  .user_img {
    // width: 100%;
    // height: 100%;
    border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;
    object-fit: cover;
  }

  .user_name {
    color: #595d6e;
    font-weight: normal;
  }
}

.cell-1-undefined .iWyFZZ div:first-child {
  word-wrap: pre-wrap !important;
}

// For user create
/* Styling for the form container */
.create {
  max-width: 400px;
  // margin: 0 auto;
  margin-left: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

/* Styling for form labels */
form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  cursor: pointer;
}

/* Styling for form inputs */
form input,
form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Styling for submit button */
.sbtn [type='submit'] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Optional: Style form inputs when they are focused */
form input:focus,
form textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}

// user create form
.radio-inline-container label {
  display: inline;
  margin-right: 15px;
  /* You can adjust the margin as needed */
}

// light-box
.lightbox-backdrop {
  z-index: 9999 !important;
}

.lightbox-backdrop .lightbox-description {
  display: none;
}

.faq_ckeditor_h .ck-editor__editable {
  height: 120px;
}

// .card-header .list_of_Faq button {
//   float: right;
// }
.card-header .list_of_Faq {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delet_faq {
  background: #e55353;
  border-radius: 50%;
  width: 30px !important;
  height: 31px !important;
  padding: 6px !important;
  color: #fff;
  cursor: pointer;
}

form input:focus,
form textarea:focus {
  box-shadow: 0 0 5px transparent !important;
}

.products_count .card-body>div {
  border-radius: 5px;
}

.role_checkbox .form-check .form-check-label {
  font-weight: normal;
  // width: 50px;
  // font-size: 5px !important;
}

.RadioButton {
  font-size: 13px;
  margin-top: 5px;
  font-weight: light;
}

.ck-editor__editable {
  min-height: 200px;
}

.add_orders {
  .customer_name .select__control {
    border-radius: 4px 0px 0px 4px !important;
  }

  .addnew_customer {
    border-radius: 0px 4px 4px 0px !important;
  }
}

.create_order {
  .modal {
    width: 800px !important;
  }

  .modal-dialog {
    max-width: 800px !important;
  }

  .Items_box {
    border: 1px solid #e1dede;
  }

  .Item_innerbox {
    border-right: 1px solid #e1dede;
  }

  .Item_innerbox_heading {
    border-bottom: 1px solid #e1dede;
  }

  .Item_innerbox_heading th,
  .Item_innerbox_heading td {
    border-right: 1px solid #e1dede;
  }

  .action-btn {
    justify-content: center;
  }
}

.addnew_customer_model {
  span {
    font-size: 13px !important;
    color: #8f8f8f;
  }
}

.tab-buttons {
  button {
    margin-left: 10px;
  }
}

.address_section_popup {
  .end_button {
    text-align: end !important;
  }
}

.parcent_logo_box {
  .parcent_logo {
    border: 1px solid #8f8f8f;
    border-radius: 4px 0px 0px 4px;
    border-right: 0px;
    padding: 5px;
  }

  .form-control {
    border-radius: 0px 4px 4px 0px !important;
  }
}

#react-select-3-listbox {
  background-color: #fff !important;
}

.addnew_customer,
.tab-buttons .active,
.tab-buttons .active:focus-visible,
.add_row,
.add_row:active,
.add_hover {
  background-color: #089bab !important;
  border: 1px solid #089bab !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.tab-buttons button,
.tab-buttons button:hover,
.tab-buttons button:active {
  background: rgba(8, 155, 171, 0.13) !important;
  border: 1px solid #dff2f4 !important;
  color: #fff !important;
  border-radius: 5px !important;
  margin: 0 !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  color: #089bab !important;
  border-radius: 4px !important;
  margin-right: 5px !important;
}

.table .action-btn {
  position: relative;
  top: 15px;
}

.addnew_customer {
  border-radius: 4px !important;
}

.regioun_child {
  margin-left: 20px;
}

// showuser user profile style
.profile_side_bar {
  .profile_picture {
    width: 100px;
    height: 100px;
  }

  .profile_picture img {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .usr_name {
    padding-left: 20px;
  }

  .usr_name h1 {
    font-size: 1rem;
    color: #48465b;
    font-weight: 500;
  }

  .user_role_btn {
    // margin-right: 0.4rem !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    padding: 5px 15px !important;
    background-color: #0abb87 !important;
    border-color: #0abb87 !important;
    font-size: 13px !important;
    color: #ffffff !important;
    box-shadow: transparent !important;
  }

  .btn-success:hover {
    color: #fff;
    background-color: #08976d;
    border-color: #078b64;
  }

  .user_details .row {
    padding-bottom: 6px;
  }

  .user_details h6 {
    color: #48465b;
    font-weight: 500;
    font-size: 14px !important;
  }

  .active_status .status_button {
    height: auto;
    min-width: 83px;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px !important;
    padding: 4px 20px;
    background: #2d9dd9;
  }

  .user_details span {
    font-size: 14px !important;
    color: #74788d;
  }

  .profile_tab_icon a svg {
    // color: #5d78ff;
    font-size: 16px;
    margin-right: 5px;
  }

  .profile_tab_icon a {
    text-decoration: none;
  }

  .profile_tab_icon a span {
    color: #525252cc;
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  .profile_tab_icon a:hover {
    // color: #5d78ff;
    background-color: #f2f3f7;
  }

  .profile_tab_icon a .active-link span {
    color: #007bff;
    /* Change to your desired active link color */
    font-weight: bold;
    text-decoration: none;
  }

  .heads {
    font-size: 14px;
    font-weight: 500;
  }
}

// Personal Information

.personalheads {
  padding: 0.5 rem;
  font-size: 14px;
  font-weight: 500;
  color: black;
  text-align: center;
  height: auto;
  text-align: start;
}

.personalinfo {
  // margin-top: -0.5 rem;
  padding: 0.5 rem;
  font-size: 14px;
  color: #595d6e;
  font-weight: 500;
  color: #7c7a7f;
  text-align: start;
  height: auto;
}

.has-error input {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

/* Inside your component's CSS file or a global CSS file */

/* Add any additional styling you need for the active link */

.custom-edit-button {
  font-size: 16px;
  cursor: pointer;
  text-align: end;

  svg {
    width: 1rem !important;
    height: 1rem !important;
  }
}

.basic {
  border-bottom: 0.5px solid;
}

// Change password
/* Green for active status */
.bg-success {
  background-color: #3bb77e;
}

.yellow {
  color: var(--secondaryColor);
}

/* Yellow for inactive status */
.bg-warning {
  background-color: var(--secondaryColor);
}

/* Red for blacklist status */
.bg-danger {
  background-color: #ff5c75;
}

//Shyamu Profile Overview Code
.profileOverview {
  .profileOverviewRow {
    border: none;
    border-bottom: 1px dotted #a8aaaf;

    h6 {
      color: rgb(52, 53, 56);

      p {
        color: rgb(156, 156, 160);
      }
    }

    h5 {
      color: #3a9dd9;
    }
  }
}

//Shyamu Basic Info Code
.shyamuBasicInfo {
  h5 {
    font-size: 1rem;
  }

  h6 {
    color: rgb(52, 53, 56);
    font-size: 0.8rem;
  }

  p {
    color: rgb(97, 97, 99);
    margin-bottom: 0px;
    font-weight: normal;
  }

  div {
    font-weight: 500;
    font-size: 15px;
  }
}

.clicked-checkbox {
  border: 2px solid #007bff;
  /* Change color as needed */
  padding: 2px;
}

.no-border-second-row tr:nth-child(2) {
  border-bottom: none;
}

.unassigned_sim {
  .sim_status_btn .sim_status {
    color: #0abb87 !important;
    border-color: #0abb87 !important;
    background: #fff !important;
  }

  .sim_status_btn .sim_status:hover {
    color: #ffffff !important;
    background-color: #0abb87 !important;
    border-color: #0abb87 !important;
  }
}

.spinner_outerbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

//Shop side bar
.ShopProfile_side_bar {
  .member_profile {
    .carousel {
      height: 360px;
      width: 100%;

      .carousel-inner {
        height: 100%;

        .carousel-item {
          height: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
      }
    }
  }

  .profile_picture {
    width: 100px;
    height: 100px;
    min-width: 100px;
  }

  .profile_picture img {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  // .usr_name {
  //   padding-left: 20px;
  // }
  .usr_name h1 {
    font-size: 1rem;
    color: #48465b;
    font-weight: 500;
  }

  .shop_address {
    font-size: 12px;
  }

  .user_role_btn {
    // margin-right: 0.4rem !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    padding: 5px 15px !important;
    background-color: #0abb87 !important;
    border-color: #0abb87 !important;
    font-size: 13px !important;
    color: #ffffff !important;
    box-shadow: transparent !important;
  }

  .btn-success:hover {
    color: #fff;
    background-color: #08976d;
    border-color: #078b64;
  }

  .user_details .row {
    padding-bottom: 6px;
  }

  .user_details h6 {
    color: #48465b;
    font-weight: 500;
    font-size: 14px !important;
  }

  .active_status .status_button {
    height: auto;
    min-width: 83px;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px !important;
    padding: 4px 20px;
    background: #2d9dd9;
  }

  .user_details span {
    font-size: 14px !important;
    color: #74788d;
  }

  .profile_tab_icon svg {
    color: #5d78ff;
    font-size: 16px;
    margin-right: 5px;
  }

  .profile_tab_icon a {
    text-decoration: none;
  }

  .profile_tab_icon a span {
    color: #525252cc;
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  .profile_tab_icon a :hover {
    color: #5d78ff;
    background-color: #f2f3f7;
  }

  .active_nav {
    background: #eaeaea;
    border-radius: 4px;
  }

  nav a.active {
    color: green;
  }

  .heads {
    font-size: 14px;
    font-weight: 500;
  }

  .Executive_name {
    font-size: 14px !important;
    color: #74788d;
  }
}

//Shop all code
.shop_address,
.checkin_show_data {
  white-space: pre-wrap !important;
  color: #595d6e;
  font-weight: 400;
}

.pagetable .rdt_TableBody .rdt_TableRow .rdt_TableRow {
  padding: 0px !important;
}

.dahnUA div:first-child {
  padding: 5px 0px !important;
  white-space: normal !important;
}

.data_Table_title {
  align-items: center;
}

.lightbox-container .lightbox-img-thumbnail {
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
}

.img_outer_box {
  width: 40px !important;
  height: 40px !important;

  img {
    width: 100%;
    height: 100%;
  }
}

.search_by_simno {
  .basic-multi-select {
    border-color: #089bab;
    border: 1px solid #089bab;
    border-radius: 3px;
  }
}

.search_by_simno,
.search_by_simno:focus,
.css-t3ipsp-control:hover {
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 5px;
}

.form-control:focus {
  border-color: hsl(0 0% 79% / 1) !important;
}

/* create order table */
.order_create_table .table-responsive {
  overflow-x: unset !important;
}

.product_img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  padding: 4px;
  object-fit: contain;
}

//Show unassigned
.sim_Last_to-up {
  h6 {
    font-weight: bold;
    font-size: 14px !important;
  }

  p {
    font-size: small;
    font-weight: normal;
  }
}

.Date_last_top-up {
  font-size: 14px;
  font-weight: bold;
}

.amount {
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
}

.commission {
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
}

.row_title {
  justify-content: start;
}

.row_amount {
  margin-left: 8px;
}

.row_commission {
  margin-left: 35px;
}

.customer_checkbox .form-check .form-check-input:focus {
  box-shadow: none !important;
}

.customer_checkbox .credit .form-check-input:checked {
  font-size: 16px !important;
  background-color: #0abb87;
}

.customer_checkbox .credit .form-check-input {
  border: 2px solid #0abb87 !important;
  margin-top: 0.25rem !important;
}

.customer_checkbox .debit .form-check-input:checked {
  font-size: 16px !important;
  background-color: #fd1361;
}

.customer_checkbox .debit .form-check-input {
  border: 2px solid #fd1361 !important;
  margin-top: 0.25rem !important;
}

.order_details {
  .order-no-heading {
    color: #fff;
    font-size: 16px;
  }

  .f_14 {
    font-size: 12px;
  }

  // .card-header {
  //   background-color: #fff;
  // }

  .payment_mode {
    columns: #fff;
    background-color: #fd1361;
    margin: 0px 15px 0px;
    padding: 2px 6px;
    border-radius: 7px;
    width: 70px;
    text-align: center;
  }

  .payment_status {
    columns: #fff;
    background-color: #008000;
    margin: 0px 15px 0px;
    padding: 2px 6px;
    border-radius: 7px;
    width: 70px;
    text-align: center;
  }

  .detils-tables tr td:nth-child(2) {
    font-weight: 300;
  }

  .detils-tables tr td:nth-child(3) {
    font-weight: 300;
  }

  .detils-tables {
    tr {
      border-style: none !important;
      border-color: transparent !important;
      background-color: transparent !important;
      font-size: 14px !important;
    }

    td {
      padding: 3px 5px !important;
    }
  }

  .item_details_table thead tr td {
    font-size: 500;
  }
}

.form-check-input {
  // margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 5px;
}

html:not([dir='rtl']) .form-check .form-check-input {
  margin-left: 0px !important;
  margin: 0px;
}

.form-check {
  .form-check-label {
    display: flex;
  }
}

form label {
  margin-bottom: 0px !important;
}

.custome_switch_icon .form-check-input {
  font-size: 16px !important;
}

.p_Details {
  .product-img {
    img {
      width: 50px;
      min-width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}

.ordered-pd-img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  object-fit: contain;
}

.user_prsl_details {
  font-weight: 500 !important;
}

.bg-0abb87 {
  color: #0abb87 !important;
}

.bg-5578eb {
  color: #5578eb !important;
}

.bg-282a3c {
  color: #282a3c !important;
}

.bg-fd397a {
  color: #fd397a !important;
}

.bg-ffb822 {
  color: var(--secondaryColor) !important;
}

.bg-b963c3 {
  color: #b963c3 !important;
}

.dashboard_card {
  height: 100%;
  max-height: 490px;
  overflow-y: hidden;
}

.dashboard_card_table {
  max-height: 340px;
  overflow-y: hidden;
}

.dashboard_card:hover,
.dashboard_card_table:hover {
  overflow-y: auto;
}

// .iWyFZZ div:first-child {
//   white-space: pre-wrap !important;
// }
// .data_Table_title div {
//   word-wrap: pre-wrap !important;
// }
.data_Table_title {
  .product_name {
    white-space: pre-wrap !important;
  }

  .product_slug {
    font-weight: 300 !important;
  }
}

.Coupon-code {
  padding: 5px 8px;
  text-transform: capitalize;
  font-size: 10px;
  text-align: center;
  width: 6rem;
  font-weight: 400;
  border-radius: 0.25rem;
}

.product-qyt {
  color: #fff;
  padding: 5px 8px;
  border-radius: 1.5rem;
  text-transform: capitalize;
  font-size: 10px;
  text-align: center;
  width: 1rem;
  float: left;
}

.width-6 {
  width: 6rem;
}

.pl-0 {
  padding-left: 0px;
}

.update_btn {
  background-color: #787c83;
  border-color: #787c83;
  transition: background-color 0.3s ease;
}

.update_btn:hover {
  background-color: #9da5b1;
  border-color: #9da5b1;
}

.header_main_search {
  .select-search-options {
    padding-left: 0;
  }

  .select-search-options .select-search-options {
    padding-left: 10px !important;
  }

  .select-search-has-focus,
  .select-search-input:hover,
  .select-search-has-focus .select-search-select {
    border-color: #089bab !important;
  }
}

.react-datepicker__navigation--years-upcoming:before {
  border-color: #827d7d;
  border-style: solid;
  border-width: 3px 3px 0 0;
  display: block;
  height: 11px;
  left: 35%;
  right: 0;
  content: '';
  position: absolute;
  top: 15px;
  width: 11px;
  transform: rotate(317deg);
}

.react-datepicker__navigation--years-previous:before {
  border-color: #827d7d;
  border-style: solid;
  border-width: 3px 3px 0 0;
  display: block;
  height: 11px;
  left: 35%;
  right: 0;
  content: '';
  position: absolute;
  bottom: 15px;
  width: 11px;
  transform: rotate(134deg);
}

.data_Table_title img {
  object-fit: cover;
  object-position: top;
  // border-radius: 50%;
}

.card-header {
  // background-color: #222b40 !important;
  background-color: var(--primaryColor) !important;
  color: #fff !important;
}

.dashboard_boxes .chart-wrapper {
  pointer-events: none;
}

// Dashboard Gradiants
.gradient1 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, rgb(48 48 50) 0%, rgb(65 229 64) 100%);
  border: 0;
}

.gradient2 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, #a54039 0%, #f77e05 100%);
  border: 0;
}

.gradient3 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, #373954 0%, #2e9079 100%);
  border: 0;
}

.gradient4 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, #373954 0%, #6a0909 100%);
  border: 0;
}

.gradient5 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, #0a0d41 0%, #06795f 100%);
  border: 0;
}

.gradient6 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, #723375 0%, #2945b4 100%);
  border: 0;
}

.gradient7 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, #c87001 0%, #366e5c 100%);
  border: 0;
}

.gradient8 {
  background: linear-gradient(90deg, rgb(255 82 20) 0%, #13b3d7 0%, #102a87 100%);
  border: 0;
}

.table-container {
  table {
    vertical-align: baseline;

    thead {
      th {
        font-weight: 500;
        padding: 1rem;
        border: 1px solid #ebedf2;
      }
    }

    tbody {
      vertical-align: baseline;

      td {
        border: 1px solid #ebedf2;
        background: transparent;
      }
    }
  }

  .product_variant_table {
    width: 180%;
  }

  .no-image-container {
    position: relative;
    display: inline-block;

    .no-image-placeholder {
      cursor: pointer;

      /* Add pointer cursor to indicate it's clickable */
      img {
        width: 65px;
        height: 65px;
        background: #fff;
        border-radius: 10px;
        /* margin-top: 20px; */
        border: 1px solid #d2d2d5;
        object-fit: cover;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
    }

    .form-control {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      /* Make the input transparent to hide it */
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .preview_image img {
    width: 65px;
    height: 65px;
    margin: 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

.main_parent_nav {
  margin-top: 30px;
}

.main_parent_nav>a {
  font-weight: 500;
  font-size: 80% !important;
}

// shimmer css
.custom-table-shimmer {
  margin-bottom: 30px !important;
}

.shimmer-table-row {
  box-shadow: none !important;
  padding: 16px 0 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.shimmer-table-col {
  height: 15px !important;
}

.shimmer-table-row--header {
  background-color: #fff !important;
  box-shadow: none;
  margin: 0;
  border-radius: 10px 10px 0 0 !important;
  height: 52px !important;
}

.custom-table-shimmer div .shimmer-table-row:last-child {
  border-radius: 0 0 10px 10px !important;
  border: 0 !important;
}

.switch-data {
  max-height: 350px;
  overflow-y: auto;
}

.switch-data .form-check {
  margin-bottom: 10px;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* Webkit browsers */
  margin: 0;
  /* Removes default margin */
}

// .category_lavel{
//   margin-left:10px;
// }
.imgDesignbtn {
  color: #089bab;
}

.makeitprofessionalimg {
  width: 100%;
  height: 200px;

  #previewImage {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 10px;
      border: 1px solid #d2d2d5;
      object-fit: cover;
      object-position: top;
    }
  }
}

.selected {
  position: relative;

  .selected-items {
    position: absolute;
    top: -10px;
    z-index: 9999;
    background: green;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: -12px;
    padding: 5px;

    svg {
      width: 100%;
      height: 100%;
      font-size: 12px;
      color: #fff;
      font-weight: 900;
    }
  }
}

.w-85 {
  width: 85%;
}

.featured_mamber {
  border: 1px solid black !important;
}

.editor-wrapper-main {
  width: 99% !important;
}

.sizeW {
  font-size: 0.8rem;
}

.black-color {
  color: #000 !important;
}

.range_block {

  .ruler,
  .labels {
    display: none !important;
  }

  .multi-range-slider {
    .thumb {
      &::before {
        width: 25px;
        height: 25px;
        border: none;
        margin: -10px -12px;
        box-shadow: none;
        background-color: rgb(223, 60, 60);
      }

      .caption {
        display: none !important;
      }
    }

    .bar-inner {
      background-color: #1f66d7;
      box-shadow: none;
      border: 0;
    }

    box-shadow: none;
    border: 0;
    padding: 0 10px !important;
  }

  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  // border-bottom: 1px solid #3c444f;
}

.nav {
  // color: #000;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: green;
}

.nav-pills .nav-link {
  color: #000000db;
}

.personalinfo-section {
  padding: 12px 5px;
}

.ShopProfile_side_bar .profile_tab_icon svg {
  color: #696b74;
}

.ShopProfile_side_bar .profile_tab_icon a :hover {
  color: #ffb822;
}

.bg-chatBadge {
  background-color: var(--primaryColor);
  margin-bottom: 20px;
  margin-top: 20px;
}

.social-linkicon {
  height: 35px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #31505f;
  color: #fff;
  border-radius: 5px;
}


.create_order .Item_innerbox_heading {
  border-bottom: none !important;
}

.form-check-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-check-input {
  margin-right: 0.5rem;
  cursor: pointer;
}

.radioBtn_name {
  margin-left: 0.5rem;
}

.maleBtn,
.femaleBtn {
  margin-right: 1rem;
}

// .download-btn:hover {
//   background: #d9d9d9;
//   border-radius: 5px;
//   padding: 5px;
// }

// .download-btn:hover svg {
//   margin-left: 1rem !important;
//   margin-right: 1rem !important;
// }

// .delet-btn:hover {
//   background: #d9d9d9;
//   border-radius: 5px;
//   padding: 5px;
// }

// .delet-btn:hover svg {
//   margin-right: 0px !important;
//   margin-left: 0px !important;
// }

// .edit-btn:hover,
// .delet-btn:hover {
//   background: #d9d9d9;
//   border-radius: 5px;
// }